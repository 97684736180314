import React, { useState } from 'react'
import './Single.css'
import { useDispatch, useSelector } from 'react-redux'
import { acCart, acDec, acInc, acRemoveItem } from '../../Context/Cart'
import { useNavigate, useParams } from 'react-router-dom'
import items from '../../constants/array'
import { postCart } from '../../api/cart/post/postCart'
import Header from '../../Components/Header/Header'

export const Single = () => {
  const navigate = useNavigate()

  const params = useParams()
  const id = params.id
  console.log(id)
  const item = items.filter((i) => Number(i.id) === Number(id))[0]
  console.log(item, items)
  const cart = useSelector((state) => state.cart)
  const i = cart?.find((it) => it?.id === item?.id)
  const [image, setImage] = useState(0)
  const [buy, setBuy] = useState(i?.quantity || 0)
  const [modal, setModal] = useState(false)

  const dispatch = useDispatch()

  return (
    <>
      <Header />
      {item && (
        <div className='single mw360' id='single'>
          <h4 id='name'>{item.name}</h4>
          <div className='showing'>
            <button className='open' onClick={() => setModal(true)}>
              <img src='https://lktimgs.vercel.app//open.svg' alt='' />
            </button>
            <img src={item.images[image]} alt='' />
            <section className='imgs'>
              {item.images.map((i, index) => (
                <img
                  src={item.images[index]}
                  style={index === image ? { border: '2px solid #fff' } : {}}
                  alt=''
                  onClick={() => {
                    setImage(index)
                  }}
                />
              ))}
            </section>
          </div>
          <div className='info'>
            <h1>{item.title}</h1>
            <p>{item.description}</p>
            <section className='buy'>
              <section
                className='count'
                style={buy ? { display: 'flex' } : { display: 'none' }}
              >
                <button
                  onClick={() => {
                    setBuy(buy - 1)
                    dispatch(buy === 1 ? acRemoveItem(item) : acDec(item))
                  }}
                >
                  -
                </button>
                <p>
                  {buy}
                  <span>В корзине</span>
                </p>
                <button
                  onClick={() => {
                    setBuy(buy + 1)
                    dispatch(acInc(item))
                  }}
                >
                  +
                </button>
              </section>
              <button
                style={{ color: 'black' }}
                onClick={() => {
                  setBuy(1)
                  dispatch(acCart(item))

                  if (!buy) {
                    postCart(item)
                  }

                  if (buy) {
                    navigate('/cart')
                  }
                }}
              >
                {buy ? 'Оформить заказ' : 'В корзину'}
              </button>
              <section className='alert'>
                <img src='https://lktimgs.vercel.app//Attention.svg' alt='' />
                <p>
                  Поставляются устройства с мощностью излучения 0,15Вт, на
                  частоте 433МГц
                </p>
              </section>
            </section>
            <section>
              <div className='characters'>
                <h2>Характеристики</h2>
                <div className='character'>
                  <p>RAM</p>
                  <p>{item.RAM}</p>
                </div>
                <div className='character'>
                  <p>Интерфейс</p>
                  <p>{item.interface}</p>
                </div>
                <div className='character'>
                  <p>Частота</p>
                  <p>{item.Hz}</p>
                </div>
                <div className='character'>
                  <p>Длинная характеристика</p>
                  <p>{item.longer}</p>
                </div>
                {item?.gain && (
                  <div className='character'>
                    <p>Усиление</p>
                    <p>{item.gain}</p>
                  </div>
                )}

                {item?.outputImpedance && (
                  <div className='character'>
                    <p>Выходное сопротивление </p>
                    <p>{item.outputImpedance}</p>
                  </div>
                )}

                {item?.weight && (
                  <div className='character'>
                    <p>Вес</p>
                    <p>{item.weight}</p>
                  </div>
                )}

                {item?.length && (
                  <div className='character'>
                    <p>Длина</p>
                    <p>{item.length}</p>
                  </div>
                )}

                {item?.material && (
                  <div className='character'>
                    <p>Материал</p>
                    <p>{item.material}</p>
                  </div>
                )}
              </div>
            </section>
          </div>
          <div className='category'>
            <h2>Все комплексы</h2>
            {items &&
              items.map(({ name, title }, index) => (
                <div
                  className='homediv'
                  onClick={() => {
                    window.scroll(0, 0)
                    navigate(`/single/${index}`)
                  }}
                >
                  <img src='https://lktimgs.vercel.app//H2.jpg' alt='' />
                  <section>
                    <h1>{name}</h1>
                    <p>{title}</p>
                  </section>
                </div>
              ))}
          </div>
          <div
            className='modal'
            style={modal ? { display: 'flex' } : { display: 'none' }}
          >
            <button className='close' onClick={() => setModal(false)}>
              x
            </button>
            <h2>{item.name}</h2>
            <h3>{image + 1 + ' из ' + item.images.length}</h3>
            <div className='showing'>
              <img src={item.images[image]} alt='' />
            </div>
            <section className='imgs'>
              {item.images.map((i, index) => (
                <img
                  src={item.images[index]}
                  style={index === image ? { border: '2px solid #fff' } : {}}
                  alt=''
                  onClick={() => {
                    setImage(index)
                  }}
                />
              ))}
            </section>
          </div>
        </div>
      )}
    </>
  )
}
