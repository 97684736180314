// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 360px;
  width: 100%;
}

header section {
  display: flex;
  gap: 20px;
}

.logo {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.logo h1 {
  font-size: 24px;
  font-weight: 500;
}


@media (min-width: 1024px) {
	header {
		max-width: 80vw;
	}
}`, "",{"version":3,"sources":["webpack://./src/Components/Header/Header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;;AAGA;CACC;EACC,eAAe;CAChB;AACD","sourcesContent":["header {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n  max-width: 360px;\r\n  width: 100%;\r\n}\r\n\r\nheader section {\r\n  display: flex;\r\n  gap: 20px;\r\n}\r\n\r\n.logo {\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 10px;\r\n  cursor: pointer;\r\n}\r\n\r\n.logo h1 {\r\n  font-size: 24px;\r\n  font-weight: 500;\r\n}\r\n\r\n\r\n@media (min-width: 1024px) {\r\n\theader {\r\n\t\tmax-width: 80vw;\r\n\t}\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
