// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.agreement {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.agreement h1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
}

.agreement h2 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
}

.agreement p {
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Agreement/Agreement.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".agreement {\r\n  padding: 12px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 30px;\r\n}\r\n\r\n.agreement h1 {\r\n  font-size: 24px;\r\n  font-weight: 700;\r\n  line-height: 28.8px;\r\n}\r\n\r\n.agreement h2 {\r\n  font-size: 16px;\r\n  font-weight: 700;\r\n  line-height: 22.4px;\r\n}\r\n\r\n.agreement p {\r\n  font-size: 14px;\r\n  font-weight: 500;\r\n  line-height: 19.6px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
