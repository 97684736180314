import React, { useEffect, useState } from 'react'
import './Cart.css'
import del from '../../images/delete.svg'
import { getCart } from '../../api/cart/get/getCart'
import { deleteCart } from '../../api/cart/delete/deleteCart'
import { putCart } from '../../api/cart/put/putCart'
import Spinner from '../../Components/Spinner/Spinner'
import Header from '../../Components/Header/Header'

export const Cart = () => {
  const data = JSON?.parse(localStorage?.getItem('cart')) || false
  console.log(data)

  const [cartItems, setCartItems] = useState([])
  const [isDeletion, setIsDeletion] = useState(false)

  const fetchCartItems = () => {
    getCart().then((data) => setCartItems(data.data))
  }

  useEffect(() => {
    fetchCartItems()
  }, [])

  useEffect(() => {
    console.log('data', data, 'cartItems', cartItems)
  }, [data, cartItems])

  const onDeleteCartItem = async (id) => {
    setIsDeletion(true)

    await deleteCart(id)

    fetchCartItems()
    setIsDeletion(false)
  }

  const onChangeCount = async (id, count) => {
    await putCart(id, count)
    fetchCartItems()
  }

	return (
    <>
      <Header />
      <div className='cart mw360'>
        <h1>Корзина</h1>
        {cartItems && cartItems.length > 0 ? (
          cartItems.map((item) => (
            <div className='cartItem'>
              <section className='itemInfo'>
                <img src={item?.attributes.img} alt='' />
                <h3>
                  {item.attributes.name} <p>{item.attributes.title}</p>
                </h3>
              </section>
              <span className='line'></span>
              <section className='control'>
                <section>
                  <button onClick={() => onDeleteCartItem(item.id)}>
                    {isDeletion ? <Spinner /> : <img src={del} alt='' />}
                  </button>
                  <button>
                    <img src={require('../../images/saved.png')} alt='' />
                  </button>
                </section>
                <section className='count'>
                  <button
                    onClick={() => {
                      if (Number(item.attributes.count) === 1) {
                        onDeleteCartItem(item.id)
                      } else {
                        onChangeCount(
                          item.id,
                          Number(item.attributes.count) - 1
                        )
                      }
                    }}
                  >
                    -
                  </button>
                  <p>
                    {item.attributes.count}
                    <span>В корзине</span>
                  </p>
                  <button
                    onClick={() =>
                      onChangeCount(item.id, Number(item.attributes.count) + 1)
                    }
                  >
                    +
                  </button>
                </section>
              </section>
            </div>
          ))
        ) : (
          <h2> В корзине нет предметов</h2>
        )}
      </div>
    </>
  )
}
