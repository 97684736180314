import React from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { Home } from './Pages/Home/Home'
import { Single } from './Pages/Single/Single'
import { Provider } from 'react-redux'
import { store } from './Context/store'
import { Order } from './Pages/Order/Order'
import { Cart } from './Pages/Cart/Cart'
import { QA } from './Pages/QA/QA'
import { About } from './Pages/About/About'
import { Agreement } from './Pages/Agreement/Agreement'
import { Main } from './Pages/main/Main'

export const App = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <Routes>
          <Route path='/shopping-cart' element={<Home />} />
          <Route path='/order' element={<Order />} />
          <Route path='/single/:id' element={<Single />} />
          <Route path='/cart' element={<Cart />} />
          <Route path='/qa' element={<QA />} />
          <Route path='/about' element={<About />} />
          <Route path='/agreement' element={<Agreement />} />
          <Route path='/' element={<Main />} />
        </Routes>
      </Provider>
    </BrowserRouter>
  )
}
