import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Home.css'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import items from '../../constants/array'

export const Home = () => {
  const navigate = useNavigate()

  return (
    <>
      <Header />
      <div className='home mw360'>
        <div className='first homediv'>
          <img src='https://lktimgs.vercel.app//H3.jpg' alt='' />
          <section>
            <section>
              <h2>ПРОГРАММНЫЙ КОМПЛЕКС </h2>
              <h1>ГРАНЬ</h1>
              <p>
                Новейшая эффективная разработка ЛОКТАР АРМ СОФТВЕР для войск
                радио-электронной борьбы
              </p>
            </section>
            <button className='demo'>Запросить демо</button>
          </section>
        </div>
        <div className='homediv_container'>
          {' '}
          {items &&
            items.map(({ name, title }, index) => (
              <div
                className='homediv'
                onClick={() => navigate(`/single/${index}`)}
              >
                <img src='https://lktimgs.vercel.app//H2.jpg' alt='' />
                <section>
                  <h1>{name}</h1>
                  <p>{title}</p>
                </section>
              </div>
            ))}
        </div>
      </div>
      <Footer />
    </>
  )
}
