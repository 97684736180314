// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form {
  background: #18162c;
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 12px;
  border-radius: 12px;
}

form input {
  height: 45px;
  background: none;
  border: 1px solid #363555;
  padding: 0 15px;
  border-radius: 8px;
}

form p {
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
}

form button {
  height: 55px;
  background: #fff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Order/Order.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,yBAAyB;EACzB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":["form {\r\n  background: #18162c;\r\n  display: flex;\r\n  flex-direction: column;\r\n  padding: 12px;\r\n  gap: 12px;\r\n  border-radius: 12px;\r\n}\r\n\r\nform input {\r\n  height: 45px;\r\n  background: none;\r\n  border: 1px solid #363555;\r\n  padding: 0 15px;\r\n  border-radius: 8px;\r\n}\r\n\r\nform p {\r\n  font-size: 14px;\r\n  font-weight: 500;\r\n  line-height: 19.6px;\r\n}\r\n\r\nform button {\r\n  height: 55px;\r\n  background: #fff;\r\n  border: none;\r\n  border-radius: 8px;\r\n  font-size: 16px;\r\n  font-weight: 700;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
