// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qa {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.qa h1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  margin-bottom: 20px;
}

.question {
  padding: 12px;
  background-color: #18162c;
  border-radius: 10px;
  transition: all 1s ease;
}

.question h2 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
}

.ask {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question.active .ask img {
  transform: rotate(180deg);
  transition: all 1s ease;
}

.answer {
  display: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  margin-top: 10px;
  transition: all 1s ease;
}

.question.active .answer {
  display: flex;
}

.quest {
  padding: 12px;
  background-color: #18162c;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.quest button {
  width: 100%;
  height: 56px;
  border: none;
  background: #fff;
  border-radius: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/QA/QA.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".qa {\r\n  padding: 12px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 12px;\r\n}\r\n\r\n.qa h1 {\r\n  font-size: 24px;\r\n  font-weight: 700;\r\n  line-height: 28.8px;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.question {\r\n  padding: 12px;\r\n  background-color: #18162c;\r\n  border-radius: 10px;\r\n  transition: all 1s ease;\r\n}\r\n\r\n.question h2 {\r\n  font-size: 16px;\r\n  font-weight: 700;\r\n  line-height: 22.4px;\r\n}\r\n\r\n.ask {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n}\r\n\r\n.question.active .ask img {\r\n  transform: rotate(180deg);\r\n  transition: all 1s ease;\r\n}\r\n\r\n.answer {\r\n  display: none;\r\n  font-size: 14px;\r\n  font-weight: 500;\r\n  line-height: 19.6px;\r\n  margin-top: 10px;\r\n  transition: all 1s ease;\r\n}\r\n\r\n.question.active .answer {\r\n  display: flex;\r\n}\r\n\r\n.quest {\r\n  padding: 12px;\r\n  background-color: #18162c;\r\n  border-radius: 10px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 12px;\r\n}\r\n\r\n.quest button {\r\n  width: 100%;\r\n  height: 56px;\r\n  border: none;\r\n  background: #fff;\r\n  border-radius: 12px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
