import axios from 'axios'

export const postCart = async (data) => {
  try {
    const response = await axios.post(
      `https://ambitious-cherry-5529a21eca.strapiapp.com/api/carts`,
      {
        data,
      },
      {
        headers: {
          Authorization:
            'Bearer b70976cea2da70600010de868b54ee7cbc10b34f5ece08acf5ab9211195da3fbb938d208368e5bdc6b6367853a16d5979a1595ccc90d4f184523eea230cfebcb740a23a808d4285805213972fc1b28cd089dc51f9973f9bc9b37a02e2d4a9975a72122c2e1648c36396fb69d8b0d1c23c39b777d03b742f8d5782c0cafdb3a3c',
        },
      }
    )
    return response.data
  } catch (err) {
    console.error(err.toJSON())
  }
}
