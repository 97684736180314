const items = [
  {
    name: 'Шорох-У',
    title: 'Мобильный комплекс связи',
    images: [
      'https://lktimgs.vercel.app//S1.png',
      'https://lktimgs.vercel.app//S2.png',
      'https://lktimgs.vercel.app//S3.png',
    ],
    img: 'https://lktimgs.vercel.app//S1.png',
    description:
      'Абонентское устройство «Шорох-У» позволяет организовать малозаметную приватную сеть для работы внутри групп. Обеспечивает скрытную коммуникацию в непосредственной близости от радиоэлектронных средств разведки противника. Модуль низкоскоростной передачи данных на базе LoRa модема (шумоподобный сигнал) в форм-факторе чехла для смартфона. Диапазоны частот от 137 до 1020 мГц (по пожеланиям заказчика). Передача данных — до 2 км в реальных условиях. Несколько «Шорох-У» могут быть объединены в единую транспортную mesh сеть.',
    RAM: '16 Gb',
    interface: 'Type-c, 3,5mm',
    longer: 'Длинное значение характеристики',
    Hz: 345,
    id: 0,
    count: 1,
  },
  {
    name: 'Шорох',
    title: 'Мобильный комплекс связи',
    images: [
      'https://lktimgs.vercel.app//S1.png',
      'https://lktimgs.vercel.app//S2.png',
      'https://lktimgs.vercel.app//S3.png',
    ],
    img: 'https://lktimgs.vercel.app//S1.png',
    description:
      'Ретранслятор «Шорох» позволяет увеличить емкость сети и дальность покрытия абонентских устройств «Шорох-У». Модуль низкоскоростной передачи данных на базе LoRa (шумоподобный сигнал). Диапазон частот — 430 МГц. Передача данных — до 3 км в реальных и до 15 км в идеальных условиях. Несколько ретрансляторов «Шорох» и абонентских устройств «Шорох-У» могут быть объединены в единую транспортную mesh сеть.',
    RAM: '16 Gb',
    interface: 'Type-c, 3,5mm',
    longer: 'Длинное значение характеристики',
    Hz: 345,
    id: 1,
    count: 1,
  },
  {
    name: 'Дымка',
    title: 'Мобильный комплекс связи',
    images: [
      'https://lktimgs.vercel.app//S1.png',
      'https://lktimgs.vercel.app//S2.png',
      'https://lktimgs.vercel.app//S3.png',
    ],
    img: 'https://lktimgs.vercel.app//S1.png',
    description:
      'Индивидуальный носимый автоматический дрон-детектор с функцией подачи сигнала тревоги в случае приближения БПЛА. Определяет сигнал на наиболее распространенных частотах коммерческих БПЛА и FPV на частотах 2.4 и 5.8 ГГц, позволяет выбрать тип сигнализации и настроить уровень помехи для конкретного участка ЛБС. Крепится на систему MOLLE. Наиболее низкая стоимость среди аналогов.',
    RAM: '16 Gb',
    interface: 'Type-c, 3,5mm',
    longer: 'Длинное значение характеристики',
    Hz: 345,
    id: 2,
    count: 1,
  },
  {
    name: 'Туман',
    title: 'Мобильный комплекс связи',
    images: [
      'https://lktimgs.vercel.app//S1.png',
      'https://lktimgs.vercel.app//S2.png',
      'https://lktimgs.vercel.app//S3.png',
    ],
    img: 'https://lktimgs.vercel.app//S1.png',
    description:
      'Аппаратно-программный комплекс на базе программно-определяемого радио (SDR) для обнаружения и противодействия БПЛА — индивидуальный сканер эфира и детектор БПЛА. Подходит как для индивидуального использования, так и для применения в составе многоузловой cистемы. Автоматически сканирует частотный диапазон 0.4–6 ГГц. Дальность обнаружения дронов — до 2.5 км. Имеет функционал сигнализации – настраивается как на Mavic и аналоги, так и на FPV. Решает задачи определения наличия каналов связи БПЛА и целеуказания для подавителя. ',
    RAM: '16 Gb',
    interface: 'Type-c, 3,5mm',
    longer: 'Длинное значение характеристики',
    Hz: 345,
    id: 3,
    count: 1,
  },
  {
    name: 'Грань',
    title: 'Мобильный комплекс связи',
    images: [
      'https://lktimgs.vercel.app//S1.png',
      'https://lktimgs.vercel.app//S2.png',
      'https://lktimgs.vercel.app//S3.png',
    ],
    img: 'https://lktimgs.vercel.app//S1.png',
    description:
      'Мобильный комплекс радио-электронной разведки местности и тактической осведомленности. Объединяет в одном устройстве смартфон с предустановленными тактическими приложениями и картами, радиостанцию, дрон-детектор и анализатор спектра. Позволяет отслеживать задаваемый диапазон частот и сигнализировать о появлении БПЛА, пеленговать источник радио-сигнала с одного или с нескольких устройств, наносить местоположение засеченного объекта на карту и передавать данные (предупреждения об обнаружении, точки, засечки, пеленги, текстовые и аудио-сообщения) через шифрованный чат между постами РЭР, на командный пункт или в смежные подразделения. Разработан на базе анализаторов спектра российского производства и зарекомендовавшего себя программного обеспечения по детекции дронов. Модульная компоновка позволяет адаптировать комплекс под требования заказчика. Диапазон сканирования — 32-6200 МГц (зависит от типа используемого анализатора спектра). Дистанция сканирования — 1-15 км (зависит от силы сигнала).',
    RAM: '16 Gb',
    interface: 'Type-c, 3,5mm',
    longer: 'Длинное значение характеристики',
    Hz: 345,
    id: 4,
    count: 1,
  },
  {
    name: 'Скрежет',
    title: 'Мобильный комплекс связи',
    images: [
      'https://lktimgs.vercel.app//S1.png',
      'https://lktimgs.vercel.app//S2.png',
      'https://lktimgs.vercel.app//S3.png',
    ],
    img: 'https://lktimgs.vercel.app//S1.png',
    description:
      'Программное обеспечение «Скрежет» обеспечивает связь тактического звена средствами технического маскирования голосовых сообщений. Позволяет повысить защищенность открытых каналов связи, например, аналоговых радиостанций. Интегрируется в волновые и проводные сети связи, не требует сопряжения с радиостанциями по кабелю. Есть режим хо-репитера для ретрансляции получаемых передач. Понятный интерфейс, прост в использовании и не имеет регламентных ограничений на применение (в отличии от ЗАС).',
    RAM: '16 Gb',
    interface: 'Type-c, 3,5mm',
    longer: 'Длинное значение характеристики',
    Hz: 345,
    id: 5,
    count: 1,
  },
  {
    name: 'Фидер',
    title: 'Мобильный комплекс связи',
    images: [
      'https://lktimgs.vercel.app//S1.png',
      'https://lktimgs.vercel.app//S2.png',
      'https://lktimgs.vercel.app//S3.png',
    ],
    img: 'https://lktimgs.vercel.app//S1.png',

    description:
      'Кабельная сборка N-male/8D-FB/SMA-male 1 м Применима для установки на установки внешних антенн на устройства «Шорох» и «Шорох-У». Электрические параметры: Импеданс — 50±2 Ом Затухание, частота 900 МГц – 0,14 дБ/м Затухание, частота 1800 МГц – 0,21 дБ/м Затухание, частота 2000 МГц – 0,22 дБ/м Затухание, частота 2500 МГц – 0,25 дБ/м Механические параметры: Тип разъема — N-male – SMA-male Диаметр оболочки — 11,1 мм Материал оболочки — PVC Температура эксплуатации — от -40 до +75 °C Вес — 0,16кг/м',
    RAM: '16 Gb',
    interface: 'Type-c, 3,5mm',
    longer: 'Длинное значение характеристики',
    Hz: 345,
    id: 6,
    count: 1,
  },
  {
    name: 'Штатив',
    title: 'Мобильный комплекс связи',
    images: [
      'https://lktimgs.vercel.app//S1.png',
      'https://lktimgs.vercel.app//S2.png',
      'https://lktimgs.vercel.app//S3.png',
    ],
    img: 'https://lktimgs.vercel.app//S1.png',
    description:
      'Предназначен для установки ретрансляционного оборудования и выносных антенн выше уровня земли для повышения дальности покрытия. Легкая и прочная конструкция, надежное крепление элементов.',
    RAM: '16 Gb',
    interface: 'Type-c, 3,5mm',
    longer: 'Длинное значение характеристики',
    Hz: 345,
    id: 7,
    count: 1,
  },

  {
    name: 'Антенна Retevis RHD-771 разъем SMA-M',
    title: 'Мобильный комплекс связи',
    images: [
      'https://lktimgs.vercel.app//S1.png',
      'https://lktimgs.vercel.app//S2.png',
      'https://lktimgs.vercel.app//S3.png',
    ],
    img: 'https://lktimgs.vercel.app//S1.png',
    description:
      'Антенна совместима с устройствами «Шорох» и «Шорох-У», подходит для использования в части распространенных аналоговых радиостанций.',
    RAM: '16 Gb',
    outputImpedance: '50 Ом',
    gain: '2.15 дБ',
    weight: '34 г',
    length: '37.5 см',
    material: 'металл, пластик',
    interface: 'Type-c, 3,5mm',
    longer: 'Длинное значение характеристики',
    Hz: 345,
    id: 8,
    count: 1,
  },
]

export default items
