// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 30px;
  padding: 20px;
  max-width: 360px;
  width: 100%;
}

footer section {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.line {
  width: 100%;
  height: 1px;
  border: 1px solid #fff;
}

footer a {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
}

footer .logo {
  flex-direction: row;
}

footer h2 {
  color: #7b75e1;
  font-size: 12px;
  font-weight: 400;
}


@media (min-width: 1024px) {
	footer {
		max-width: 80vw;
	}
}`, "",{"version":3,"sources":["webpack://./src/Components/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,SAAS;EACT,aAAa;EACb,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,SAAS;AACX;;AAEA;EACE,WAAW;EACX,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,qBAAqB;EACrB,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;;AAGA;CACC;EACC,eAAe;CAChB;AACD","sourcesContent":["footer {\r\n  display: flex;\r\n  flex-direction: column;\r\n  height: auto;\r\n  gap: 30px;\r\n  padding: 20px;\r\n  max-width: 360px;\r\n  width: 100%;\r\n}\r\n\r\nfooter section {\r\n  display: flex;\r\n  flex-direction: column;\r\n  width: 100%;\r\n  gap: 20px;\r\n}\r\n\r\n.line {\r\n  width: 100%;\r\n  height: 1px;\r\n  border: 1px solid #fff;\r\n}\r\n\r\nfooter a {\r\n  color: #fff;\r\n  text-decoration: none;\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 5px;\r\n}\r\n\r\nfooter .logo {\r\n  flex-direction: row;\r\n}\r\n\r\nfooter h2 {\r\n  color: #7b75e1;\r\n  font-size: 12px;\r\n  font-weight: 400;\r\n}\r\n\r\n\r\n@media (min-width: 1024px) {\r\n\tfooter {\r\n\t\tmax-width: 80vw;\r\n\t}\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
